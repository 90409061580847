<template>
    <section class="publish-management">
        <div class="top-bar bg-white">
            <el-button type="primary" icon="el-icon-plus" @click="handleAddEditPublish('add',{})"
                       class="addPublish" size="small">新增房型</el-button>
            <el-input v-model="formSearch.keyword" placeholder="请输入关键字搜索" style="width: 250px" size="small"/>
            <div style="width: 9px;"></div>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
        </div>

        <r-e-table class="bg-white" ref="publishTableRef" :dataRequest="getPublishTableData" :columns="tableColumn"
                   :afterDataReq="afterDataReq" :query="formSearch" :height="820">
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="handleAddEditPublish('edit', row)">查看</span>
                        <span class="table-btn" @click="handleRelationPublish(row)">关联房源</span>
                        <span class="table-btn" @click="handlePublishDelete(row)">删除</span>
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
        <!--    添加、修改房源弹框   -->
        <layer-add-edit-publish ref="publishRef"/>
        <!--    关联房源弹框   -->
        <layer-relation-publish class="layer-relation-publish" ref="relationRef"/>
    </section>
</template>

<script>
    import {tableColumn} from "@/views/rental-housing-management/publish-create/data";
    import {layerAddEditPublish, layerRelationPublish} from "@/views/rental-housing-management/publish-create/components";
    import {MessageSuccess,} from "@custom/message";
    export default {
        name: "publish-create",
        data() {
            return {
                tableColumn,
                formSearch: {
                    keyword: null
                }
            };
        },
        components: {layerAddEditPublish, layerRelationPublish},
        mounted() {
        },
        methods: {
            afterDataReq(e) {
                e.forEach(v => {
                    v.areaM = isNaN(v.area) ? v.area : v.area + "㎡";
                });
            },
            getPublishTableData(params) {
                // return api.getPublishListApi(params);
                return [];
            },
            handleSearch() {
                this.$refs["publishTableRef"].getTableData();
            },
            handleReset(){
                this.formSearch.keyword = null;
                this.handleSearch();
            },
            handleAddEditPublish(addEdit, row) {
                // 新增、编辑打开弹窗
                this.$refs["publishRef"].openDialog(addEdit, row);
            },
            handleRelationPublish(row) {
                // 关联房源打开弹窗
                this.$refs.relationRef.layerRelationPublishInit(row);
            },
            handlePublishDelete(row){
                const {uuid} = row;
                this.$confirm('此操作将删除该房型, 是否继续?', '注意', { type: 'warning'}).then(() => {}).catch(() => {});
            }
        }
    };
</script>

<style lang="scss" scoped>
    .publish-management {
        padding: 0 VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(15px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .addPublish {
                position: absolute;
                left: VW(40px);
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>
